type ReviewProps = {
  username: string;
  review: string;
  title: string;
}

export const Review = ( { username, review, title }: ReviewProps ) => {
  const firstLetter = username.charAt( 0 ) ?? '';

  return (
    <div className={ 'relative flex flex-col items-center gap-4 px-[18px] py-[18px] pt-[34px] md:px-[22px] md:py-[22px] md:pt-[40px] border-2 border-white/20 rounded-md shadow-[0px_3.461px_17.306px_0px_rgba(255,255,255,0.20)_inset] bg-[#0C102E]/20 w-[310px]' }>
      <div className={ 'absolute inset-x-0 -top-[31px] flex items-center justify-center' }>
        <div className={ 'bg-[#4D7365] w-[62px] h-[62px] rounded-full border border-white/[0.25] flex items-center justify-center text-white text-2xl' }>
          { firstLetter }
        </div>
      </div>
      <div className={ 'flex flex-col items-center' }>
        <div className={ 'text-center font-bold' }>{ username }</div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="88"
            height="14"
            viewBox="0 0 88 14"
            fill="none">
            <path
              d="M15.5 5.35039H9.77202L8.00276 0L6.22798 5.35039L0.5 5.34496L5.13878 8.65504L3.36399 14L8.00276 10.6953L12.636 14L10.8668 8.65504L15.5 5.35039Z"
              fill="#04DA8D"/>
            <path
              d="M11.2648 9.86512L10.8668 8.65504L8.00276 10.6953L11.2648 9.86512Z"
              fill="#00532C"/>
            <path
              d="M51.5 5.35039H45.772L44.0028 0L42.228 5.35039L36.5 5.34496L41.1388 8.65504L39.364 14L44.0028 10.6953L48.636 14L46.8668 8.65504L51.5 5.35039Z"
              fill="#04DA8D"/>
            <path
              d="M47.2648 9.86512L46.8668 8.65504L44.0028 10.6953L47.2648 9.86512Z"
              fill="#00532C"/>
            <path
              d="M33.5 5.35039H27.772L26.0028 0L24.228 5.35039L18.5 5.34496L23.1388 8.65504L21.364 14L26.0028 10.6953L30.636 14L28.8668 8.65504L33.5 5.35039Z"
              fill="#04DA8D"/>
            <path
              d="M29.2648 9.86512L28.8668 8.65504L26.0028 10.6953L29.2648 9.86512Z"
              fill="#00532C"/>
            <path
              d="M69.5 5.35039H63.772L62.0028 0L60.228 5.35039L54.5 5.34496L59.1388 8.65504L57.364 14L62.0028 10.6953L66.636 14L64.8668 8.65504L69.5 5.35039Z"
              fill="#04DA8D"/>
            <path
              d="M65.2648 9.86512L64.8668 8.65504L62.0028 10.6953L65.2648 9.86512Z"
              fill="#00532C"/>
            <path
              d="M87.5 5.35039H81.772L80.0028 0L78.228 5.35039L72.5 5.34496L77.1388 8.65504L75.364 14L80.0028 10.6953L84.636 14L82.8668 8.65504L87.5 5.35039Z"
              fill="#04DA8D"/>
            <path
              d="M83.2648 9.86512L82.8668 8.65504L80.0028 10.6953L83.2648 9.86512Z"
              fill="#00532C"/>
          </svg>
        </div>
      </div>
      <div>
        <div className="text-center text-pretty line-clamp-5 lg:line-clamp-4 font-semibold mb-2">{ title }</div>
        <div className="text-center text-pretty line-clamp-5 lg:line-clamp-4 text-sm whitespace-pre-line">{ review }</div>
      </div>
    </div>
  )
}