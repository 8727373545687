import { Icon, type IconName } from '../ui/Icon'

type IconInfoBlockProps = {
  iconName?: IconName;
  title?: string;
  content?: string;
}

export const IconInfoBlock = ( { iconName, title, content } : IconInfoBlockProps ) => {
  return (
    <div className={ 'relative p-5 border border-[#564AD7] rounded-2xl shadow-[0px_-5px_24px_0px_rgba(255,255,255,0.45)_inset] w-full overflow-hidden self-stretch' }>
      <div className={ 'flex flex-col gap-2.5 pb-[45px]' }>
        <div className={ 'text-white text-xl font-onest font-semibold' }>{ title }</div>
        <div className={ 'text-white font-light' }>{ content }</div>
      </div>
      { iconName &&
        <Icon
          name={ iconName }
          className='absolute -bottom-2.5 right-2 icon-size-20 text-[#AE88EB]/30 opacity-[0.9] drop-shadow-[0px_5.786px_8.679px_rgba(0,0,0,0.30)]' />
      }
    </div>
  )
}
